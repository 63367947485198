import { Layout } from "../UI/Layout/Layout.tsx";
import { useClassRoomById } from "../ClassRoom/useClassRoomById.ts";
import { ScreenTitleBlock } from "../UI/Layout/ScreenTitleBlock.tsx";
import {
  ClassRoomFragmentFragment,
  SpeakingClassLevel,
} from "../gql/graphql.ts";
import { useUpsertLessonFeedback } from "./useUpsertLessonFeedback.ts";
import dayjs from "dayjs";
import { FullPageLoading } from "../UI/Loading/FullPageLoading.tsx";
import { ErrorFeedback } from "../UI/Feedback/ErrorFeedback.tsx";
import { useClassRoomIdParamsOrThrow } from "../ClassRoom/useClassRoomIdParamsOrThrow.ts";
import { useToSentFeedbackThankYou } from "./useToSentFeedbackThankYou.ts";
import { Card } from "../UI/Card.tsx";
import { GiveLessonFeedbackTabs } from "./GiveLessonFeedbackTabs.tsx";

export const GiveLessonFeedbackScreenPath =
  "/classroom/:classRoomId/give-feedback";

type LessonFeedbackBagDto = {
  userId: string;
  targetLanguageScore: number;
  participationScore: number;
  notes: string;
  noShow: boolean;
  differentSpeakingLevelSuggested?: SpeakingClassLevel;
};

function GiveLessonFeedbackScreenInner({
  classRoom,
}: {
  classRoom: ClassRoomFragmentFragment;
}) {
  const { toSentFeedbackThankYou } = useToSentFeedbackThankYou();

  const { upsertUserLessonFeedback } = useUpsertLessonFeedback();

  async function saveLessonFeedbacks(
    classRoom: ClassRoomFragmentFragment,
    feedbacks: LessonFeedbackBagDto[],
  ) {
    await upsertUserLessonFeedback({
      classRoomId: classRoom.id,
      lessonFeedbacks: feedbacks,
    });
  }

  return (
    <Layout>
      <div className={"space-y-3"}>
        <Card>
          <ScreenTitleBlock
            hideLineDivider
            title={`Lesson feedback for the lesson on ${dayjs(
              classRoom.startedAtUtc,
            ).format("dddd DD MMMM YYYY [at] HH:mm")}`}
            subTitle={
              <div>
                <p>
                  Topic name:{" "}
                  <span className={"font-semibold"}>
                    {classRoom.topic.name}
                  </span>
                </p>
                <p>
                  Speaking level:{" "}
                  <span className={"font-semibold"}>
                    {classRoom.speakingLevel}
                  </span>
                </p>
              </div>
            }
          />
        </Card>
        <Card>
          <p>
            To view the feedback provided by other teachers to the students in
            this class, please refer to{" "}
            <a
              target={"_blank"}
              rel={"noopener noreferrer"}
              href={classRoom.feedbackReportUrl}
              className={"underline text-blue-600"}
            >
              this report
            </a>
          </p>
        </Card>
        <Card>
          <GiveLessonFeedbackTabs
            classRoom={classRoom}
            saveLessonFeedbacks={saveLessonFeedbacks}
            toSentFeedbackThankYou={toSentFeedbackThankYou}
          />
        </Card>
      </div>
    </Layout>
  );
}

export function GiveLessonFeedbackScreen() {
  const { classRoomId } = useClassRoomIdParamsOrThrow();
  const { classRoom, loading } = useClassRoomById(classRoomId);
  if (loading) return <FullPageLoading />;
  if (!classRoom)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This class does not exist"}
          />
        </div>
      </Layout>
    );
  if (classRoom.participants.length <= 0)
    return (
      <Layout>
        <div className={"flex justify-center"}>
          <ErrorFeedback
            title={"Oops!"}
            message={"This class has no participants"}
          />
        </div>
      </Layout>
    );

  return <GiveLessonFeedbackScreenInner classRoom={classRoom} />;
}
