export const dateFormatter = (d: string): string => {
  return new Date(d).toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
};

export const getInitials = (firstName: string, lastName: string): string => {
  const parts: string[] = [];
  if (firstName.length > 0) parts.push(firstName);
  if (lastName.length > 0) parts.push(lastName);

  if (parts.length <= 0) return "";
  return parts.map((p) => p[0].toUpperCase()).join("");
};
