import { ProgressBar } from "../UI/ProgressBar.tsx";
import { Tabs } from "../UI/Tabs/Tabs.tsx";
import { getFragmentData } from "../gql";
import { ClassRoomParticipantFragment } from "../ClassRoom/ClassRoomParticipantFragment.ts";
import { TabItem } from "../UI/Tabs/TabItem.tsx";
import {
  GiveLessonFeedbackParticipantForm,
  GiveLessonFeedbackParticipantFormInput,
} from "./GiveLessonFeedbackParticipantForm.tsx";
import {
  ClassRoomFragmentFragment,
  ClassRoomParticipant,
  SpeakingClassLevel,
} from "../gql/graphql.ts";
import _values from "lodash.values";
import { useCallback, useState } from "react";

type LessonFeedbackBagDto = {
  userId: string;
  targetLanguageScore: number;
  participationScore: number;
  notes: string;
  noShow: boolean;
  differentSpeakingLevelSuggested?: SpeakingClassLevel;
};

type Props = {
  classRoom: ClassRoomFragmentFragment;
  saveLessonFeedbacks: (
    classRoom: ClassRoomFragmentFragment,
    feedbacks: LessonFeedbackBagDto[],
  ) => Promise<void>;
  toSentFeedbackThankYou: (classRoomId: string) => void;
};
export function GiveLessonFeedbackTabs({
  classRoom,
  saveLessonFeedbacks,
  toSentFeedbackThankYou,
}: Props) {
  const [selectedParticipantIndex, setSelectedParticipantIndex] =
    useState<number>(0);
  const [lessonFeedbacks, setLessonFeedbacks] = useState<
    Record<string, LessonFeedbackBagDto>
  >(
    classRoom.participants.reduce(
      (acc, p) => {
        const participant = getFragmentData(ClassRoomParticipantFragment, p);
        acc[participant.id] = {
          targetLanguageScore: 0,
          participationScore: 0,
          noShow: false,
          notes: "",
          userId: participant.id,
        };
        return acc;
      },
      {} as Record<string, LessonFeedbackBagDto>,
    ),
  );
  function getSizeOfCompletedLessonFeedbacks(
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).filter(isCompletedLessonFeedback).length;
  }
  function isCompletedLessonFeedback(
    lf: LessonFeedbackBagDto | undefined,
  ): boolean {
    if (!lf) return false;
    if (lf.noShow) return true;
    return lf.targetLanguageScore > 0 && lf.participationScore > 0;
  }
  const getSelectedParticipant = useCallback(
    (classRoom: ClassRoomFragmentFragment): ClassRoomParticipant => {
      const participant = classRoom.participants[selectedParticipantIndex];
      if (!participant)
        throw new Error(
          `Cannot find participant ${selectedParticipantIndex} for class room ${classRoom.id}`,
        );
      return getFragmentData(ClassRoomParticipantFragment, participant);
    },
    [selectedParticipantIndex],
  );
  const areSameLessonFeedbacks = useCallback(
    (
      a: LessonFeedbackBagDto | undefined,
      b: LessonFeedbackBagDto | undefined,
    ) => {
      return (
        a?.participationScore === b?.participationScore &&
        a?.targetLanguageScore === b?.targetLanguageScore &&
        a?.noShow === b?.noShow &&
        a?.notes === b?.notes &&
        a?.userId === b?.userId &&
        a?.differentSpeakingLevelSuggested ===
          b?.differentSpeakingLevelSuggested
      );
    },
    [],
  );
  const onChangeCurrentValuation = useCallback(
    (data: GiveLessonFeedbackParticipantFormInput) => {
      if (!classRoom) return;
      setLessonFeedbacks((prev) => {
        const selectedParticipant = getSelectedParticipant(classRoom);
        const newLf: LessonFeedbackBagDto = {
          targetLanguageScore: data.targetLanguageScore,
          participationScore: data.participationScore,
          notes: data.notes ?? "",
          userId: selectedParticipant.id,
          noShow: data.noShow,
          differentSpeakingLevelSuggested: data.differentSpeakingLevelSuggested,
        };
        if (areSameLessonFeedbacks(prev[selectedParticipant.id], newLf))
          return prev;
        return {
          ...prev,
          [selectedParticipant.id]: newLf,
        };
      });
    },
    [getSelectedParticipant, classRoom, areSameLessonFeedbacks],
  );
  function getDefaultValueByParticipantId(
    participantId: string,
  ): GiveLessonFeedbackParticipantFormInput {
    const values = lessonFeedbacks[participantId];
    if (values)
      return {
        notes: values.notes,
        participationScore: values.participationScore,
        targetLanguageScore: values.targetLanguageScore,
        noShow: values.noShow,
        shouldBeInDifferentLevel: !!values.differentSpeakingLevelSuggested,
        differentSpeakingLevelSuggested: values.differentSpeakingLevelSuggested,
      };

    return {
      notes: "",
      participationScore: 0,
      targetLanguageScore: 0,
      noShow: false,
      shouldBeInDifferentLevel: false,
    };
  }
  function getFirstNotCompletedParticipantIndex(
    classRoom: ClassRoomFragmentFragment,
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ): number {
    return classRoom.participants.findIndex(
      (p) =>
        !isCompletedLessonFeedback(
          lessonFeedbacks[getFragmentData(ClassRoomParticipantFragment, p).id],
        ),
    );
  }

  async function onSave(
    classRoom: ClassRoomFragmentFragment,
    data: GiveLessonFeedbackParticipantFormInput,
  ): Promise<void> {
    const selectedParticipant = getSelectedParticipant(classRoom);
    const newLessonFeedbacks: Record<string, LessonFeedbackBagDto> = {
      ...lessonFeedbacks,
      [selectedParticipant.id]: {
        targetLanguageScore: data.targetLanguageScore,
        participationScore: data.participationScore,
        notes: data.notes ?? "",
        userId: selectedParticipant.id,
        noShow: data.noShow,
        differentSpeakingLevelSuggested: data.differentSpeakingLevelSuggested,
      },
    };

    if (hasCompletedFeedback(newLessonFeedbacks)) {
      await saveLessonFeedbacks(classRoom, _values(newLessonFeedbacks));
      toSentFeedbackThankYou(classRoom.id);
    } else {
      setSelectedParticipantIndex(
        getFirstNotCompletedParticipantIndex(classRoom, newLessonFeedbacks),
      );
    }
    setLessonFeedbacks(newLessonFeedbacks);
  }
  function isTheLastOne(classRoom: ClassRoomFragmentFragment) {
    const size = getSizeOfCompletedLessonFeedbacks(lessonFeedbacks);
    const participant = getSelectedParticipant(classRoom);
    return (
      size >= classRoom.participants.length - 1 &&
      !isCompletedLessonFeedback(lessonFeedbacks[participant.id])
    );
  }
  function hasCompletedFeedback(
    lessonFeedbacks: Record<string, LessonFeedbackBagDto>,
  ) {
    return _values(lessonFeedbacks).every(isCompletedLessonFeedback);
  }
  const selectedParticipant = getSelectedParticipant(classRoom);
  return (
    <>
      <div>
        <ProgressBar
          label={"Feedback"}
          max={classRoom.participants.length}
          current={getSizeOfCompletedLessonFeedbacks(lessonFeedbacks)}
        />
      </div>
      <div>
        <Tabs>
          {classRoom.participants.map((p, i) => {
            const participant = getFragmentData(
              ClassRoomParticipantFragment,
              p,
            );
            return (
              <TabItem
                key={participant.id}
                onClick={() => setSelectedParticipantIndex(i)}
                selected={selectedParticipantIndex === i}
                label={
                  <p className={"flex space-x-2"}>
                    <span>
                      {participant.givenName} {participant.familyName}
                    </span>
                    <span>
                      {isCompletedLessonFeedback(
                        lessonFeedbacks[participant.id],
                      )
                        ? "✅"
                        : "❌"}
                    </span>
                  </p>
                }
              />
            );
          })}
        </Tabs>
      </div>
      <GiveLessonFeedbackParticipantForm
        onChange={onChangeCurrentValuation}
        key={selectedParticipant.id}
        defaultValues={getDefaultValueByParticipantId(selectedParticipant.id)}
        onSave={(data) => onSave(classRoom, data)}
        saveButtonLabel={
          isTheLastOne(classRoom) || hasCompletedFeedback(lessonFeedbacks)
            ? "Send"
            : "Save and proceed"
        }
        participant={getSelectedParticipant(classRoom)}
      />
    </>
  );
}
