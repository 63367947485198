import { useAuth0 } from "@auth0/auth0-react";
import { getInitials } from "../../Franchisee/FranchiseeMetods.tsx";
import { Logo } from "./Logo.tsx";
import { useLoggedTeacher } from "../../Auth/useLoggedTeacher.ts";
import { useContext, useState } from "react";
import {
  ArrowLeftStartOnRectangleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { SvgTeacher } from "../Svgs/SvgTeacher.tsx";
import { AuthCtx } from "../../Auth/AuthCtx.tsx";

export function NavBarWithLoggedUser() {
  const [open, setOpen] = useState<boolean>(false);
  const { user: authTeacher } = useAuth0();
  const { teacher } = useLoggedTeacher(!authTeacher);
  const teacherInitials = getInitials(
    teacher?.givenName ?? "",
    teacher?.familyName ?? "",
  );
  const { signOut } = useContext(AuthCtx);

  const handleOpen = (): void => {
    setOpen(!open);
  };
  return (
    <div
      className={
        "fixed z-30 p-4 min-h-16 bg-white shadow-xl text-sm shadow-slate-200/10 border-b border-slate-100 w-full flex items-center justify-between"
      }
    >
      <Logo />
      <div className="flex gap-2 justify-center items-center">
        <div className="h-8 w-8 text-xs text-white rounded-full bg-norma-fucsia flex items-center justify-center select-none">
          <button className="w-full h-full" onClick={handleOpen}>
            {teacherInitials}
          </button>
          <div
            className={`${
              open
                ? "opacity-100 scale-100"
                : "opacity-0 scale-95 pointer-events-none"
            } transition-all duration-300 transform ease-in-out border-[1px] translate-y-1/2 border-gray-300/30 text-black flex-col absolute mt-12 rounded-2xl shadow-lg right-4 w-fit h-fit bg-white z-20`}
          >
            <div className="w-full flex flex-col justify-center py-3 border-b-[1px] border-gray-300/40 px-4">
              <p className="font-bold text-sm">{teacher?.givenName}</p>
              <p className="text-sm">{teacher?.email}</p>
            </div>
            <div className="px-2 py-3 flex items-center text-sm border-b-[1px] border-gray-300/30">
              <UserCircleIcon className="w-5 h-5 mr-2" />
              <button>Il mio account</button>
            </div>
            <button
              onClick={signOut}
              className="px-2 py-3 flex items-center text-sm border-b-[1px] border-gray-300/30"
            >
              <ArrowLeftStartOnRectangleIcon className="w-5 h-5 mr-2" />
              <p>Esci</p>
            </button>
          </div>
        </div>
        <p className="pr-1 hidden md:block">
          {teacher?.email} <span className="pl-2">|</span>
        </p>
        <SvgTeacher />
        <p className="text-norma-b2-plus">Teacher</p>
      </div>
    </div>
  );
}
