import { ClassRoomParticipant, SpeakingClassLevel } from "../gql/graphql.ts";
import { RatingInput } from "../UI/RatingInput.tsx";
import { TextAreaInput } from "../UI/TextAreaInput.tsx";
import * as yup from "yup";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { convertToInteger } from "../DataUtils/convertToInteger.ts";
import { InputWithLabel } from "../UI/InputWithLabel.tsx";
import { PrimaryButton } from "../UI/PrimaryButton.tsx";
import { useWithLoading } from "../UI/Loading/useWithLoading.ts";
import { useEffect } from "react";
import { Select } from "../UI/Select.tsx";
import { InfoFeedback } from "../UI/Feedback/InfoFeedback.tsx";

export type GiveLessonFeedbackParticipantFormInput = {
  participationScore: number;
  targetLanguageScore: number;
  notes?: string;
  noShow: boolean;
  differentSpeakingLevelSuggested?: SpeakingClassLevel;
  shouldBeInDifferentLevel?: boolean;
};

const MAX_LESSONS_FOR_NEW_USER_ADVICE = 3;

const valuationError = "Specify a valuation from 1 to 5";
const specifyAnIntegerValueError = "Specify an integer value";
const schema = yup
  .object({
    noShow: yup.boolean().required(),
    participationScore: yup
      .number()
      .required("Evaluate the participation")
      .when("noShow", ([noShow]) => {
        if (noShow) return yup.number();
        else
          return yup
            .number()
            .integer(specifyAnIntegerValueError)
            .min(1, valuationError)
            .max(5, valuationError);
      }),
    targetLanguageScore: yup
      .number()
      .required("Evaluate the target language")
      .when("noShow", ([noShow]) => {
        if (noShow) return yup.number();
        else
          return yup
            .number()
            .integer(specifyAnIntegerValueError)
            .min(1, valuationError)
            .max(5, valuationError);
      }),
    notes: yup.string(),
    differentSpeakingLevelSuggested: yup
      .string()
      .oneOf(Object.values(SpeakingClassLevel))
      .when(["shouldBeInDifferentLevel", "noShow"], {
        is: (shouldBeInDifferentLevel: boolean, noShow: boolean) => {
          return shouldBeInDifferentLevel && !noShow;
        },
        then: (yup) =>
          yup.required("You must specify a suggested speaking level"),
      }),
  })
  .required();

type Props = {
  participant: ClassRoomParticipant;
  defaultValues?: GiveLessonFeedbackParticipantFormInput;
  saveButtonLabel: string;
  onSave: (data: GiveLessonFeedbackParticipantFormInput) => void;
  onChange: (data: GiveLessonFeedbackParticipantFormInput) => void;
};
export function GiveLessonFeedbackParticipantForm({
  participant,
  defaultValues,
  saveButtonLabel,
  onSave,
  onChange,
}: Props) {
  const {
    handleSubmit,
    register,
    formState: { errors },
    control,
    watch,
    trigger,
    setValue,
  } = useForm<GiveLessonFeedbackParticipantFormInput>({
    resolver: yupResolver(schema),
    defaultValues: {
      ...(defaultValues ?? {}),
    },
  });

  const input = watch();
  useEffect(() => {
    onChange(input);
  }, [input, onChange]);

  const { withLoading, loading } = useWithLoading();
  const onSubmit: SubmitHandler<
    GiveLessonFeedbackParticipantFormInput
  > = async (data) => {
    await withLoading(async () => onSave(data));
  };

  function shouldShowNewUserAdvice() {
    return (
      participant.executedClassRoomCount <= MAX_LESSONS_FOR_NEW_USER_ADVICE
    );
  }

  return (
    <form className={"space-y-8"}>
      <h3 className={""}>
        Assessment for{" "}
        <b>
          {participant.givenName} {participant.familyName}
        </b>{" "}
        - {participant.speakingLevel}
      </h3>
      {shouldShowNewUserAdvice() ? (
        <div className={"space-y-4"}>
          <InfoFeedback
            title={"This is a new student"}
            message={`${participant.givenName} is a new student and might not be in the right level just yet. Please, state whether you think they're in the right level.`}
          />
          <div className={"flex items-start space-x-2"}>
            <div
              className={"flex-1"}
              data-testid={"should-be-in-different-level"}
            >
              <InputWithLabel label={"Should be in different level?"}>
                <Controller
                  disabled={input.noShow}
                  control={control}
                  render={({ field }) => {
                    return (
                      <Select<boolean>
                        disabled={field.disabled}
                        value={field.value}
                        options={[
                          {
                            name: "Yes",
                            id: true,
                          },
                          {
                            name: "No",
                            id: false,
                          },
                        ]}
                        onChange={(v) => {
                          field.onChange(v);
                          trigger([
                            "differentSpeakingLevelSuggested",
                            "noShow",
                          ]).catch((e) => {
                            throw e;
                          });
                        }}
                      />
                    );
                  }}
                  name={"shouldBeInDifferentLevel"}
                />
              </InputWithLabel>
            </div>
            <div className={"flex-1"} data-testid={"suggested-different-level"}>
              <InputWithLabel label={"Suggested different level"}>
                <Controller
                  control={control}
                  disabled={!input.shouldBeInDifferentLevel}
                  render={({ field }) => {
                    return (
                      <Select<SpeakingClassLevel>
                        errorMessage={
                          errors.differentSpeakingLevelSuggested?.message
                        }
                        disabled={field.disabled}
                        value={field.value}
                        options={Object.values(SpeakingClassLevel)
                          .filter((l) => l !== participant.speakingLevel)
                          .map((l) => {
                            return {
                              name: l,
                              id: l,
                            };
                          })}
                        onChange={(v) => {
                          field.onChange(v);
                          trigger([
                            "differentSpeakingLevelSuggested",
                            "noShow",
                          ]).catch((e) => {
                            throw e;
                          });
                        }}
                      />
                    );
                  }}
                  name={"differentSpeakingLevelSuggested"}
                />
              </InputWithLabel>
            </div>
          </div>
        </div>
      ) : null}
      <div data-testid={"did-they-show-app"}>
        <InputWithLabel label={"Did they show up?"}>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <Select<boolean>
                  value={field.value}
                  options={[
                    {
                      name: "Yes",
                      id: false,
                    },
                    {
                      name: "No",
                      id: true,
                    },
                  ]}
                  onChange={(v) => {
                    field.onChange(v);
                    if (v) {
                      setValue("participationScore", 0);
                      setValue("targetLanguageScore", 0);
                      setValue("notes", "");
                      trigger("participationScore").catch((e) => {
                        throw e;
                      });
                      trigger("targetLanguageScore").catch((e) => {
                        throw e;
                      });
                      trigger("differentSpeakingLevelSuggested").catch((e) => {
                        throw e;
                      });
                    }
                  }}
                />
              );
            }}
            name={"noShow"}
          />
        </InputWithLabel>
      </div>{" "}
      <div>
        <InputWithLabel label={"Participation"}>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <RatingInput
                  disabled={input.noShow}
                  errorMessage={errors.participationScore?.message}
                  value={field.value ? convertToInteger(field.value) : 0}
                  onChange={field.onChange}
                />
              );
            }}
            name={"participationScore"}
          />
        </InputWithLabel>
      </div>
      <div>
        <InputWithLabel label={"Target language"}>
          <Controller
            control={control}
            render={({ field }) => {
              return (
                <RatingInput
                  disabled={input.noShow}
                  errorMessage={errors.targetLanguageScore?.message}
                  value={field.value ? convertToInteger(field.value) : 0}
                  onChange={field.onChange}
                />
              );
            }}
            name={"targetLanguageScore"}
          />
        </InputWithLabel>
      </div>
      <InputWithLabel label={"Notes"}>
        <TextAreaInput
          {...register("notes")}
          disabled={input.noShow}
          errorMessage={errors?.notes?.message}
        />
      </InputWithLabel>
      <div className={"flex justify-center"}>
        <div className={"flex items-center space-x-4"}>
          <PrimaryButton
            onClick={handleSubmit(onSubmit)}
            loading={loading}
            label={saveButtonLabel}
          />
        </div>
      </div>
    </form>
  );
}
